import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export const ImageApprentice = ({ src }) => {
  const data = useStaticQuery(graphql`
    query {
      jamie: file(relativePath: { eq: "Jamie.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      janis: file(relativePath: { eq: "Janis Baker 2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      matthew: file(relativePath: { eq: "Matthew.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      perrie: file(relativePath: { eq: "perrie.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      phillipPeters: file(relativePath: { eq: "phillip-peters.JPG" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      {src === "jamie" ? (
        <Img fluid={data.jamie.childImageSharp.fluid} />
      ) : src === "janis" ? (
        <Img fluid={data.janis.childImageSharp.fluid} />
      ) : src === "matthew" ? (
        <Img fluid={data.matthew.childImageSharp.fluid} />
      ) : src === "perrie" ? (
        <Img fluid={data.perrie.childImageSharp.fluid} />
      ) : src === "phillip-peters" ? (
        <Img fluid={data.phillipPeters.childImageSharp.fluid} />
      ) : src === "horns" ? (
        <Img fluid={data.horns.childImageSharp.fluid} />
      ) : (
        <div></div>
      )}
    </>
  )
}
