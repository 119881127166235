import React from "react"

import Layout from "../components/layout"
import Header from "../components/header"
import HeroUI from "../components/hero-ui"
import Section from "../components/section"
import Carousel from "../components/carousel"
import FinalCTA from "../components/final-cta"
import SEO from "../components/seo"
import { ImageApprentice } from "../components/gatsby-image/carousel-images"
import { ImageLogo } from "../components/gatsby-image/host-logos"

const IndexPage = () => (
  <Layout>
    <Header menuItems={menuItems} />
    <SEO
      title="Apprentices"
      description="There's never been a better time to host an apprentice. The NZ Government has injected $1.6 billion into vocational education. We handle the business admin and you get the apprentice."
    />
    <HeroUI
      src="horns"
      heading="Earn while you learn"
      description="With an apprenticeship you learn on-the-job, get paid and become qualified in your chosen trade."
      buttonText="Apply now"
    />

    {sectionItems.map(
      ({
        heading,
        bgColor,
        textColor,
        columnPlacement,
        secondaryIdeas,
        src,
        description,
        microheading,
        microheadingColor,
      }) => (
        <Section
          heading={heading}
          description={description}
          bgColor={bgColor}
          textColor={textColor}
          columnPlacement={columnPlacement}
          secondaryIdeas={secondaryIdeas}
          src={src}
          microheading={microheading}
          microheadingColor={microheadingColor}
        />
      )
    )}
    <JobBoard />

    {sectionWhatsInvolved.map(
      ({
        heading,
        bgColor,
        textColor,
        columnPlacement,
        secondaryIdeas,
        src,
        description,
        microheading,
        microheadingColor,
      }) => (
        <Section
          heading={heading}
          description={description}
          bgColor={bgColor}
          textColor={textColor}
          columnPlacement={columnPlacement}
          secondaryIdeas={secondaryIdeas}
          src={src}
          microheading={microheading}
          microheadingColor={microheadingColor}
        />
      )
    )}
    <SuccessStories />

    <FinalCTA
      description="There's no commitment in registering your interest, even if you just want more information."
      buttonText="Get in touch"
    />
  </Layout>
)

export default IndexPage

const JobBoard = () => {
  return (
    <div className="margin">
      <h2>Apply right here</h2>
      <iframe
        id="Iframepage-iframe"
        style={{ width: "100%", height: 800, border: "none" }}
        src="https://jobs.jobvite.com/atnz"
        class="iframe"
      ></iframe>
    </div>
  )
}

const sectionItems = [
  {
    microheading: "Why apprenticeships?",
    microheadingColor: "#074B64",
    bgColor: "#074B64",
    textColor: "white",
    heading: "Get qualified for free",
    description:
      "Unlike uni, you don't have to get a degree before you get hired. We hire you first and pay you to study on-the-job.",
    src: "thumbsUp",
    columnPlacement: 1,
    secondaryIdeas: [
      {
        icon: "wePay",
        heading: "We pay you",
        bodyText:
          "We pay you a weekly wage while you learn on-the-job at the Kiwi business you are placed into.",
      },
      {
        icon: "noLoan",
        heading: "No student loan",
        bodyText:
          "We cover all your training fees too, so you gain your NZQA-recognised qualification with absolutely no student debt. ",
      },
      {
        icon: "wrapAround",
        heading: "Wrap-around support",
        bodyText:
          "You get monthly visits from a dedicated ATNZ Account Manager who provides you with advice, coaching and mentoring, as well as assessing your bookwork and any other help you may need.",
      },
    ],
  },
]

const sectionWhatsInvolved = [
  {
    microheading: "How does it work?",
    microheadingColor: "#074B64",
    heading: "Make the training commitment",
    description:
      "An apprenticeship is a four year qualification. We      employ and pay you while you train on-the-job in      a Kiwi business for an exciting trades career.",
    bgColor: "#FFE5E0",
    src: "touch",

    columnPlacement: 2,
    secondaryIdeas: [
      {
        icon: "0",
        heading: "We offer apprenticeships in:",
        bodyText:
          "          <ul>            <li>Mechanical engineering</li>            <li>Fabrication and welding</li>            <li>Fitting, machining and toolmaking</li>            <li>Heating, ventilation and air-conditioning</li>            </ul>",
      },
      {
        icon: "0",
        heading: "Real skills for a real career",
        bodyText:
          "Apprenticeship qualifications          are created and updated in          partership with New Zealand          industries so you are taught the          skills and theory you need to          master for real world jobs and          life-long careers. ",
      },
      {
        icon: "0",
        heading: "We want to hear from you if:",
        bodyText:
          "<ul><li>You are a practical, hands-on person.</li>     <li>You have NCEA level 2 in Maths          and English. </li><li>        You are a New Zealand citizen or          permanent resident</li></ul>",
      },
    ],
  },
]

const SuccessStories = () => {
  return (
    <div className="margin success-stories">
      <p style={{ color: "#074B64" }}>
        <strong>Success stories</strong>
      </p>
      <Carousel>
        {stories.map(({ company, quote, logo, apprentice, src }) => (
          <Story
            company={company}
            quote={quote}
            logo={logo}
            apprentice={apprentice}
            src={src}
          />
        ))}
      </Carousel>
    </div>
  )
}

const Story = ({ company, logo, quote, apprentice, src }) => {
  return (
    <div className="story-card-cnt">
      <div className="story-card">
        <p className="quote">
          <span className="speech-mark">"</span>
          {quote}"
        </p>
        <div className="image-cnt">
          <div className="image">
            <ImageApprentice src={src} />
          </div>
        </div>
        <h3>{apprentice}</h3>
        <div>{company}</div>
        <div className="host-logo-cnt">
          <div className="host-logo">
            <ImageLogo logo={logo} />
          </div>
        </div>
      </div>
    </div>
  )
}

const stories = [
  {
    apprentice: "Jamie Pakoti",
    company: "Patton Engineering",
    src: "jamie",
    quote:
      "Apprenticeships give you a great foundation and an opportunity to build skills and experience that are internationally recognised",
    logo: "patton",
  },

  {
    apprentice: "Janis Baker",
    company: "Trustpower",
    src: "janis",
    quote:
      "Engineering is a great career choice and while there are challenges along the way, they’re all a part of learning a trade.",
    logo: "trustPower",
  },
  {
    apprentice: "Matthew Blackie",
    company: "Stafford Engineering",
    src: "matthew",
    quote:
      "Being an apprentice at Stafford is great. Everyone understands you’re still learning so they’re willing to lend a hand and happy to teach you.",
    logo: "stafford",
  },
  {
    apprentice: "Perrie Thomas",
    company: "Hayes International",
    src: "perrie",
    quote:
      "I like knowing I’ve made something that works and seeing it in action is really cool.",
    logo: "hayes",
  },
  {
    apprentice: "Phillip Peters",
    company: "Balance Agri-Nutrients",
    src: "phillip-peters",
    quote:
      "I put my all into my apprenticeship. It’s not just a qualification, it’s a life skill for me. A life changing event – something that’s going to back me for the rest of my life.",
    logo: "ballance",
  },
]

const menuItems = [
  {
    title: "About ATNZ",
    link: "https://atnz.org.nz/about-atnz/",
  },
  {
    title: "Our apprenticeships",
    link: "https://atnz.org.nz/become-an-apprentice/our-apprenticeships/",
  },
  {
    title: "Current vacancies",
    link: "https://atnz.org.nz/become-an-apprentice/jobs/",
  },
]
